{
   
   
        "profile": {
            "enableProfileFeature": true,
            "enableEditProfile": false,
            "enableManageBusiness": true,
            "enableManageFleet": false,
            "isRefSessionComAvailable": true
        },
        "events": {
            "enableEvents": true,
            "enableSearchInEvents": true,
            "enableCiamStatusInParticipantScreen": true,
            "enableDriftExercise":true,
            "enableSelfRegistration": true,
            "enableOffRoad":false
        },
        "locations": {
            "enableLeftPanelView": true,
            "enableSatelliteView": true,
            "enableRecenter": true,
            "enableCreatedByYouFilter": false,
            "enableCircuitFeature": true,
            "enableCircuitEdit": true,
            "enableEmotionTourFeature": false,
            "enableRoundTrip": false,
            "enableCuratedTour": false,
            "enableEditPublishedTour": true,
            "enableAudioJsonUpload": false,
            "enableoffroadfeature": true,
            "enableOffRoadCreation": true,
            "enableLanguageSelection": true,
            "showLayoutUrl" : true
        }
}