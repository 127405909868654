{
    "profile": {
        "enableProfileFeature": true,
        "enableEditProfile": false,
        "enableManageBusiness": true,
        "enableManageFleet": true,
        "isRefSessionComAvailable": true
    },
    "events": {
        "enableEvents": true,
        "enableSearchInEvents": true,
        "enableCiamStatusInParticipantScreen": true,
        "enableDriftExercise":true,
        "enableSelfRegistration":true,
        "enableOffRoad":true
    },
    "locations": {
        "enableLeftPanelView": true,
        "enableSatelliteView": true,
        "enableRecenter": true,
        "enableCreatedByYouFilter": true,
        "enableCircuitFeature": true,
        "enableCircuitEdit": true,
        "enableEmotionTourFeature": true,
        "enableRoundTrip": false,
        "enableCuratedTour": true,
        "enableEditPublishedTour": true,
        "enableAudioJsonUpload": true,
        "enableoffroadfeature": true,
        "enableOffRoadCreation": true,
        "enableLanguageSelection": true,
        "showLayoutUrl" : true
    }
}