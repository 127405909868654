import { LOCATIONS_CONSTANTS } from "./locations-constants";

let TOAST_MESSAGES = LOCATIONS_CONSTANTS.TOAST_ERROR_MSGS;

export const ERROR_CODES = {
    '1110': TOAST_MESSAGES.CIRCUIT_GUID_REQ,
    '1111': TOAST_MESSAGES.CIRCUIT_GUID_INVALID,
    '1112': TOAST_MESSAGES.INVALID_VALUE_ENETERED,
    '1410': TOAST_MESSAGES.MISSING_TRACK_DETAILS,
    '1411': TOAST_MESSAGES.TRACK_EXISTS_MSG,
    '1412': TOAST_MESSAGES.INVAID_FILE_TYPE,
    '1418': TOAST_MESSAGES.INVALID_TRACK_GUID_FORMAT,
    '1419': TOAST_MESSAGES.VIN_OR_FIN_REQUIRED,
    '6005': TOAST_MESSAGES.INVALID_COORDINATES,
    '6006': TOAST_MESSAGES.INVALID_CIRCUIT_TYPE,
    '6010': TOAST_MESSAGES.INVALID_GEOFENCE,
    '6011': TOAST_MESSAGES.GEO_FENCE_OVERLAP_ERR,
    '6015': TOAST_MESSAGES.CIRCUIT_CREATE_ERR,  
    '6019': TOAST_MESSAGES.ADDRESS_NOT_FOUND,
    '6021': TOAST_MESSAGES.UNABLE_TO_FETCH_ADDRESS, 
    '6022': TOAST_MESSAGES.ADDRESS_CANNOT_BE_EMPTY,
    '1010': TOAST_MESSAGES.CIRCUIT_NAME_REQ,
    '1011': TOAST_MESSAGES.CIRCUIT_COORDINATES_REQ,
    '1012': TOAST_MESSAGES.CIRCUIT_TYPE_REQ,
    '1014': TOAST_MESSAGES.INVALID_CIRCUIT_RADIUS,
    '6017': TOAST_MESSAGES.ET_INVALID_GUID,
    '1420': TOAST_MESSAGES.ET_GUID_REQ,
    '1421': TOAST_MESSAGES.MISMATCH_TRACK_LENGTH,
    '1615': TOAST_MESSAGES.ET_TOUR_ID_REQ,
    '1438': TOAST_MESSAGES.ET_NAME_REQ,
    '1432': TOAST_MESSAGES.ERROR_DELETING_MEDIA,
    '1610': TOAST_MESSAGES.INVALID_ANNOTATION_NAME,
    '1611': TOAST_MESSAGES.INVALID_ANNOTATION_LANDMARK,
    '1613': TOAST_MESSAGES.INVALID_ANNOTATION_PROPERTIES,
    '1612': TOAST_MESSAGES.INVALID_ANNOTATION_DESC,
    '2014': TOAST_MESSAGES.UNABLE_TO_UPLOAD_MEDIA,
    '1430': TOAST_MESSAGES.PROFILE_ID_REQUIRED,
    '1013': TOAST_MESSAGES.CIRCUIT_ALREADY_EXISTS,
    '6012': TOAST_MESSAGES.CIRCUIT_COORDINATES_REQ,
    '6013': TOAST_MESSAGES.CIRCUIT_COORDINATES_REQ,
    '1116': TOAST_MESSAGES.CIRCUIT_NOT_FOUND,
    '1514': TOAST_MESSAGES.INVALID_COORDINATES,
    '6004': TOAST_MESSAGES.INVALID_COUNTRY,
    '6002': TOAST_MESSAGES.INVALID_COUNTRY,
    '7000': TOAST_MESSAGES.INVALID_ELEVATION,
    '1476': TOAST_MESSAGES.ERROR_CREATING_TRACK_VERSION,
    '1433': TOAST_MESSAGES.EMPTY_GPS_POINTS_ERROR,
    '1435': TOAST_MESSAGES.INVALID_BOOST_STRATEGY,
    '1445': TOAST_MESSAGES.INVALID_PPM_ERROR,
    '1422': TOAST_MESSAGES.INVALID_META_DATA_ERROR,
    '1436': TOAST_MESSAGES.INVALID_MODEL_TYPE_ERROR,
    '1442': TOAST_MESSAGES.INVALID_TRACK_GUID_FORMAT,
    '1443': TOAST_MESSAGES.INVALID_ELEVATION,

}