[
    {
        "localeCode": "cs-CZ",
        "localeDescription": "Czech"
    },
    {
        "localeCode": "en-GB",
        "localeDescription": "English (Great Britain)"
    },
    {
        "localeCode": "de-DE",
        "localeDescription": "Deutsch (Germany)"
    },
    {
        "localeCode": "es-ES",
        "localeDescription": "Spanish"
    },
    {
        "localeCode": "fr-FR",
        "localeDescription": "French"
    },
    {
        "localeCode": "ko-KR",
        "localeDescription": "Korean"
    }
]